import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import ColumnRelationLink from './columnRelationLink'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0020 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam('https://www.kintaicloud.jp/')
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0006/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0020'
  )
  const routeParam3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0008/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0020'
  )
  const routeParam4 = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <ColumnH1 label="連勤は何日まで可能？法律で決まった上限日数や連勤の問題点とは" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2022.04.15</p>
      <section>
        <img src="/images/column/details/c0020.jpg" alt="キンクラコラム" />
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <p>
          1週間7日という概念はどの事業所であっても変わることはありませんが、連続勤務（以下、連勤）については各事業所によって頻度や程度の差があります。
          <br />
          今回は、連勤について
          <span>法的な側面から最大限連勤できる日数や問題点</span>
          にフォーカスをあて解説していきます。
        </p>
        <div className="toc">
          <p>目次</p>
          <ol>
            <li>
              <a href="#toc1">法律で定められた最大連勤日数</a>
            </li>
            <li>
              <a href="#toc2">変形休日制とは</a>
              <ul>
                <li>
                  <a href="#toc2-1">連勤24日のパターン</a>
                </li>
                <li>
                  <a href="#toc2-2">連勤48日のパターン</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#toc3">変形労働時間制</a>
            </li>
            <li>
              <a href="#toc4">連勤における問題点</a>
            </li>
            <li>
              <a href="#toc5">最後に</a>
            </li>
          </ol>
        </div>
        <a href={routeParam} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
        <div id="toc1">
          <h1>法律で定められた最大連勤日数</h1>
        </div>
        <p>
          労働基準法第35条1項では、
          <span>
            「使用者は労働者に対して毎週少なくとも1日の休日を与えなければならない」
          </span>
          と定めています。
          <br />
          近年、週休2日制が普及しており、例えば土日を会社としての休日と定める企業が多くなりましたが、法律上は週に1日（例えば日曜日）の休日で足りることになります。よって、この規定上、例えば日曜日を起算日とする場合、ある週の日曜日を休日とし、翌週の土曜日を休日とすると、連勤の上限日数は12日と言うことになります。
          <br />
          <br />
          しかし、注意点として、労働基準法第32条では
          <span>
            「使用者は労働者に休憩時間を除き1週間について40時間を超えて労働させてはならない。」
          </span>
          と定められています。よって、1日7時間勤務（例えば9時から17時・途中1時間休憩）の会社であれば、1日単位では残業がなかったとしても月曜日から土曜日まで勤務すると週42時間の労働時間となり、2時間分は
          <span>時間外労働</span>
          となります。よって、2時間分の<span>割増賃金の支払いが必須</span>
          となります。
          <br />
          <br />
          尚、1週間とは、就業規則等に特段の定めがない場合は日曜日から土曜日までの1週間を指します。もちろん、就業規則等に月曜日からや、日曜日からと定めることで
          <span>その曜日を起算日</span>とすることは可能です。
          <br />
          <br />
          次に休日は原則として一暦日、すなわち午前0時から午後12時までの24時間の休みを指しており、単に継続24時間を指すものではありません。
        </p>
        <div id="toc2">
          <h1>変形休日制とは</h1>
        </div>
        <p>
          毎週少なくとも1日の休日を与えなくても
          <span>4週間に4日以上の休日を与える</span>
          ことでもよいとされています。変形休日制を採用するには就業規則等に4日以上の休日を与えることとする4週間の起算日を明らかにする必要があります。
          <br />
          <br />
          4週間の考え方として、特定の4週間に4日の休日があればよく、どの4週間を区切っても4日の休日を与えていなければならないという趣旨ではありません。よって、理論上は以下の勤務もあり得ます。
          <br />
          <br />
          第1週目：休日なし
          <br />
          第2週目：休日1日
          <br />
          第3週目：休日2日
          <br />
          第4週目：休日1日
          <br />
          第5週目：休日なし
          <br />
          第6週目：休日なし
          <br />
          第7週目：休日2日
          <br />
          第8週目：休日2日
          <br />
          <br />
          上記の例では、第3週目から第6週目については3日しか休日がありませんが、特定の4週間については4週4日を満たしていることから、理論上は可能ということです。
        </p>
        <div id="toc2-1">
          <h2>連勤24日のパターン</h2>
        </div>
        <p>
          例えば第4週目の最後の4日間に4日の休日を与えれば24日（第1週目～第4週目の3日間）の連勤が理論上は可能となります。
        </p>
        <div id="toc2-2">
          <h2>連勤48日のパターン</h2>
        </div>
        <p>
          例えば第1週目の初日から起算して4日間の休日、第8週目の4日目から4日間の休日を付与することで、連続48日の連勤が理論上は可能となります。
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc3">
          <h1>変形労働時間制</h1>
        </div>
        <p>
          例外的に1か月変形労働時間制や1年単位の変形労働時間制という制度があります。端的には1か月変形労働時間制は1か月の労働時間を平均し、1週間あたりの労働時間を40時間とする制度で、1年単位の変形労働時間制は1年間の労働時間を平均し、1週間あたりの労働時間を40時間とする制度です。
          <br />
          変形労働時間制を採用する背景には月の中の特定の週や、年の中の特定の季節に繁忙期・閑散期がある場合、労働時間を柔軟に設定したいという考えがあります。
        </p>
        <ColumnRelationLink
          link={routeParam2}
          label="通常の労働時間制（固定労働時間制度）と変形労働時間制とは？"
        />
        <ColumnRelationLink
          link={routeParam3}
          label="多様な働き方、変形労働時間制とは？採用するメリットとは"
        />
        <div id="toc4">
          <h1>連勤における問題点</h1>
        </div>
        <p>
          連勤は言うまでもなく、<span>労働生産性の低下</span>
          を招き、それだけでなく、<span>労働者の健康問題</span>
          に重大な影響を与える可能性があります。特に、慢性的な連勤となると、ワークライフバランスは崩壊し、長時間労働に陥りやすいことが挙げられます。
          <br />
          そのような勤務形態が常態化すると、
          <span>社会問題化した過労死の誘引</span>
          や、そこまで至らなくても長時間労働そのものに対して使用者責任が追及され、損害賠償請求される可能性もあります。
          <br />
          <br />
          実務上は連勤24日や48日を採用するような企業はほぼありません。そもそもそのような勤務形態を採用するよりも定期的に休日を入れて労働者の心身の回復後に労務の提供を求める方が生産性は高いので、採用を検討するまでもないという理解です。
          <br />
          <br />
          また、休憩についても確認が必要です。連勤をするにあたっても
          <span>休憩は「労働時間の途中」に設けなければなりません。</span>
          1日の勤務が6時間を超える場合には少なくとも45分、8時間を超える場合には少なくとも1時間を与えなければ違法となります。
        </p>
        <div id="toc5">
          <h1 className="read">最後に</h1>
        </div>
        <p>
          連勤は労働者にとって負荷がかかり、恒常的に採用すると長期雇用の足枷となることがあり、通常の労務管理と同様に定期的に休日を設けることが適切です。
          <br />
          特に健康被害が起こってしまった場合、他の労働者は全く被害がないとは断言できませんので、職場内の生産性は大きく下がることがありますので、
          <span>連勤は必要最低限に留めておく</span>ことがよいでしょう。
        </p>
        <a href={routeParam4} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0020
